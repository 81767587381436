import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import request from '../../helpers/request';
import './bulkAllocate.scss';
import Select from 'react-select';

export default function BulkBuy ({ modalIsOpen, closeModal, filters, date, bulkEndpoint, unallocateEndpoint, workflowUsersEndpoint, mode, urlParams, title, showZeroAllocationMessage = true, reallocate = false, ...props })
{
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [bulkCount, setBulkCount] = useState(0);
    const [bulkLimit, setBulkLimit] = useState(0);
    const [buyNowEnabled, setBuyNowEnabled] = useState(true);
    const [users, setUsers] = useState([]);
    const [targetUser, updateTargetUser] = useState(null);
    const [search, setSearch] = useState('')
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [error, setError] = useState(undefined);
    const [reallocateModalIsOpen, setReallocateModalIsOpen] = useState(false)
    const urlParamsString = urlParams ?  "?" + Object.keys(urlParams).map((key, index) => {
        return (
            `${key}=${urlParams[key]}` + (Object.keys(urlParams).length === index ? '' : '&')
        )
    }).join('') : ''

    useEffect(() => {
        setStep(1);
    }, [modalIsOpen, setStep]);

    const getLimit = () => {
        setBulkCount()
        if (step === 1 && modalIsOpen) {
            setLoading(true)
          request(true).get(mode === "unallocate" ? unallocateEndpoint : bulkEndpoint + (urlParamsString ?? ''), {
                doesCancel: true,
                params: {
                    date: date,
                    filter: filters,
                    mode: mode,
                    target: targetUser?.value ?? null,
                }
            }).then(r => {
                setBulkCount(r.data);
                setBulkLimit(r.data);
                setLoading(false)
            }).catch(e => {
                console.log(e);
                setLoading(false)
                window.alert("Failed to retrieve data")
            })
        }
    }

    useEffect(() => {
        if (mode === "unallocate") {
            getLimit()
        }
    }, [targetUser])

    useEffect(() => {
        getLimit()
    }, [step, modalIsOpen, filters, date, bulkEndpoint])

    const confirmPurchase = () => {
        setBuyNowEnabled(false);
        buy();
    }

    const buy = () => {
        request(true).post(mode === "unallocate" ? unallocateEndpoint : bulkEndpoint + '/allocate' + urlParamsString ?? '', {
            date: date,
            filter: filters,
            limit: bulkLimit,
            target: targetUser?.value,
            mode: mode
        }).then(r => {
            window.location.reload();
        }).catch(e => {
            console.log(e);
        });
    }

    const formChange = (value = 0) => {
        value = Number(value)
        if (value === "") return setBulkLimit(0)

        if (value > bulkCount) {
            value = bulkCount;
        }

        setBulkLimit(value);
    }

    const firstBuyStep = () => {
        console.log('firstBuyStep', bulkLimit, bulkLimit === 0, bulkLimit === '0')
        if (bulkLimit === 0) return setError(`You cannot ${mode === "unallocate" ? "unallocate" : "allocate"} 0 customers, you must ${mode === "unallocate" ? "unallocate" : "allocate"} 1 or more.`)
        setError(undefined)
        setStep(2)
    }

    const searchForUsers = () => {
        setLoadingUsers(true)
        request(true).get(workflowUsersEndpoint, {
            doesCancel: true,
            params: {
                mode: mode,
                search: search,
                date: date,
            }
        }).then(r => {
            if (mode?.includes('unallocate')) {
                const options = [
                    ...[{'label': 'All', 'value': 'All'}],
                    ...r.data
                ]
                setUsers(options);
            } else {
                setUsers(r.data)
            }
            setLoadingUsers(false)
        }).catch(e => {
            console.error(e);
            setLoadingUsers(false)
            window.alert("Could not fetch users")
        })
    }

    useEffect(() => {
        searchForUsers()
    }, [workflowUsersEndpoint, mode, search])

    const requestClose = () => {
        updateTargetUser();
        closeModal();
    }

    return (
    <>
      <Modal
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
        className="card bulk-allocate"
        isOpen={modalIsOpen}
        onRequestClose={requestClose}
        contentLabel="Bulk Allocate COT Alert Data"
        appElement={document.getElementById('root')}
      >
        { step === 1 ?
          <>
            {title ? <h3>{title}</h3> : ''}
            <p>
              The selected data set includes { bulkCount } customers. How many would you like to {mode === "unallocate" ? "unallocate" : "allocate"}?
            </p>
            <small>
              If you reduce this number then customers with higher occupier scores will be {mode === "unallocate" ? "unallocated" : "allocated"} first.
            </small>
            {mode !== "unallocate" && showZeroAllocationMessage ? <small>
              Customers with an occupier score of 0 cannot be allocated.
            </small> : ''}
            {error ? <p className="colour-yellow"><strong>{error}</strong></p> : null} 
            <div className="input">
              <label>{mode === "unallocate" ? "Unallocate" : "Allocate"} Limit:</label>
              <div style={{display: 'flex', position: 'relative'}}>
                <input type={!loading ? "text" : "number"} name="bulk-limit" max={ bulkCount } value={ !loading ? bulkLimit : '' } disabled={loading} onChange={ (e) => formChange(e.target.value) } style={{flex: '1'}}/>
                { loading ? <div className="spinner" style={{ position: 'absolute', paddingLeft: '8px' }}>
                  <i className="fa fa-spinner fa-spin"></i>
                </div> : ''}
              </div>
            </div>
            { !mode?.includes('priority') ?
              <div className="input">
                <label>{mode === "unallocate" ? "Unallocate From" : "Allocate To"}:</label>
                <Select 
                  options={users} 
                  onChange={ updateTargetUser }
                  onInputChange={(value) => {
                    if (value !== search) {
                      setSearch(value)
                    }
                  }}
                  inputValue={search}
                  loading={loadingUsers}
                  escapeClearsValue={false}
                />
              </div>
              : ''}
            <div className={reallocate ? "grid-3" : "grid-2"}>
              <button className="button cancel" onClick={ requestClose }>Cancel</button>
              {reallocate && <button className="button confirm" onClick={ () => setReallocateModalIsOpen(true) } disabled={targetUser === null || !bulkCount}>Reallocate to...</button>}
              <button className="button confirm" onClick={ () => firstBuyStep() } disabled={(!mode?.includes('priority') && targetUser === null || !bulkCount)}>{reallocate ? "Unallocate" : "Confirm"}</button>
            </div>
          </>
          :
          <>
            <p>
              You have selected { bulkLimit } accounts to {mode?.includes('priority') ? "priority allocate" : (mode === "unallocate" ? "unallocate from " : "allocate to ") + targetUser?.label }, please press '{mode === "unallocate" ? "Unallocate" : "Allocate"} Now' to {mode === "unallocate" ? "unallocate" : "allocate"} these accounts.
            </p>
            <div className="grid-2">
              <button className="button cancel" onClick={ requestClose }>Cancel</button>
              <button className="button confirm" onClick={ confirmPurchase } disabled={ !buyNowEnabled }>
                {
                  !buyNowEnabled ?
                    <div className="spinner">
                      <i className="fa fa-spinner fa-spin"></i>
                    </div>
                    : `${mode === "unallocate" ? "Unallocate" : "Allocate"} Now`
                }
              </button>
            </div>
          </>
        }
      </Modal>
      <BulkReallocate
        bulkLimit={bulkLimit}
        bulkEndpoint={bulkEndpoint}
        workflowUsersEndpoint={workflowUsersEndpoint}
        date={date}
        filters={filters}
        users={users}
        fromUser={targetUser}
        modalIsOpen={reallocateModalIsOpen}
        closeModal={() => setReallocateModalIsOpen(false)}
      />
    </>
    )
}

// Permission gate should probably be both allocate & unallocate
const BulkReallocate = ({ 
  bulkLimit, 
  bulkEndpoint, 
  workflowUsersEndpoint,
  date, 
  filters, 
  fromUser,
  modalIsOpen, 
  closeModal 
}) => {
  const [search, setSearch] = useState("")
  const [targetUser, setTargetUser] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([])

  const reallocateAccounts = () => {
    request(true).post(bulkEndpoint + '/reallocate', {
      date: date,
      filter: filters,
      limit: bulkLimit,
      fromUser: fromUser.value,
      toUser: targetUser.value,
    }).then(r => {
        window.location.reload();
      }).catch(e => {
        console.log(e);
      });
  }

  const searchForUsers = () => {
    setLoadingUsers(true)
    request(true).get(workflowUsersEndpoint, {
      doesCancel: true,
      params: {
        mode: "reallocate",
        search: search,
        date: date,
      }
    }).then(r => {
        setUsers(r.data)
        setLoadingUsers(false)
      }).catch(e => {
        console.error(e);
        setLoadingUsers(false)
        window.alert("Could not fetch users")
      })
  }

  useEffect(() => {
    searchForUsers()
  }, [workflowUsersEndpoint, modalIsOpen, search])

  return (
    <Modal
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      className="card bulk-allocate"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Bulk Allocate COT Alert Data"
      appElement={document.getElementById('root')}
    >
      <p>
        You have selected { bulkLimit } accounts to reallocate, please select a user to allocate these accounts to, and press 'Reallocate Now' to reallocate these accounts.
      </p>
      <div className="input">
        <label>Reallocate to:</label>
        <Select 
          options={users} 
          onChange={ setTargetUser }
          onInputChange={(value) => {
            if (value !== search) {
              setSearch(value)
            }
          }}
          inputValue={search}
          escapeClearsValue={false}
        />
      </div>
      <div className="grid-2">
        <button className="button cancel" onClick={ closeModal }>Cancel</button>
        <button className="button confirm" onClick={ reallocateAccounts }>Reallocate Now</button>
      </div>
    </Modal>
  )
}
